import { Box, Button } from '@chakra-ui/react'
import { BaseLink } from './link'
import React from 'react'

export const ButtonWithSubtext = ({
  text,
  subText,
  subTextProps,
  ...props
}) => (
  <Button
    as={BaseLink}
    textAlign={'center'}
    flexDirection={'column'}
    height={14}
    fontWeight={'normal'}
    width={'100%'}
    {...props}
  >
    {text}
    <br />
    <Box fontSize={'sm'} textTransform={'initial'} mt={1} {...subTextProps}>
      {subText}
    </Box>
  </Button>
)
