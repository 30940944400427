function getDataLayer() {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer
}

export function gtmPush(data) {
  getDataLayer().push(data)
}

export function gaEvent(category = '', action = '', label = '', value = '') {
  gtmPush({
    event: 'ga-event',
    category,
    action,
    label,
    value,
  })
}
