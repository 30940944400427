import React, { useState } from 'react'
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Image,
  Text,
  useTheme,
  VStack,
} from '@chakra-ui/react'
import eventDescriptions from './eventDescriptions'
import TextContainer from '../../../elements/textContainer'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { gaEvent } from '../../../../utils/analytics'
import { eventData } from '../../../../api/eventData'
import { isDefaultIshaTicketing } from '../../../../api/events'
import { getGeoValue } from '../../../../api/queries'
import { ButtonWithSubtext } from '../../../elements/buttonWithSubtext'
import { regions } from '../../../../api/constants'

const Description = ({ eventId, bgColor }) => {
  const theme = useTheme()
  const gradientColor = bgColor || theme.colors.tan[50]
  const [showDescription, setShowDescription] = useState(false)

  return (
    <Box gridArea={'description'} mt={3}>
      <Box position={'relative'}>
        <Collapse startingHeight={'15em'} in={showDescription}>
          <TextContainer>{eventDescriptions[eventId]}</TextContainer>
        </Collapse>

        <Box
          position={'absolute'}
          bottom={0}
          w={'100%'}
          height={'100%'}
          background={`linear-gradient(to bottom, ${gradientColor}00 60%, ${gradientColor} 95%)`}
          style={{
            opacity: showDescription ? 0 : 1,
            transition: '.3s all',
            pointerEvents: 'none',
          }}
        />
      </Box>

      <IconButton
        aria-label={'Read More'}
        display={'block'}
        bgColor={'white'}
        color={'black'}
        fontSize={'30px'}
        icon={<ChevronDownIcon />}
        mt={-5}
        mx={'auto'}
        borderRadius={'100%'}
        boxShadow={'0px 3px 3px -1px #0000009c'}
        lineHeight={1}
        _hover={{ bgColor: 'white' }}
        _active={{
          bgColor: 'white',
        }}
        _focus={{
          boxShadow: '0px 1px 2px 0px #0000009c',
          transform: 'translateY(3px)',
        }}
        onClick={() => setShowDescription(true)}
        style={{ opacity: showDescription ? 0 : 1 }}
      />
    </Box>
  )
}

const Buttons = ({
  buttons,
  variant,
  slug,
  eventPricing,
  country,
  region,
  subText,
  isRecorded,
  isPast,
}) => {
  const isDefaultTicketing = isDefaultIshaTicketing(buttons)

  return (
    <Box justifySelf={'start'} gridArea={'button'} mt={2}>
      {buttons.length > 0 ? (
        <>
          {!isDefaultTicketing && (
            <Box mb={2}>
              <Text textTransform={'uppercase'}>Buy Tickets From:</Text>
              <Text fontSize={'sm'}>
                (Ticket includes a {region !== regions.oceania && 'hardcover '}
                book)
              </Text>
            </Box>
          )}
          <VStack spacing={2} alignItems={'flex-start'}>
            {buttons.map((button) => {
              const buttonText = (
                <>
                  {button.buttonText}
                  {button.price && ` - ${button.price}`}
                </>
              )

              return isDefaultTicketing && variant !== 'bookstorePage' ? (
                <ButtonWithSubtext
                  key={button.buttonLink}
                  text={buttonText}
                  subText={`Includes ${
                    region !== regions.oceania ? 'a hardcover ' : 'the '
                  }book`}
                  href={button.buttonLink}
                />
              ) : (
                <Button
                  key={button.buttonLink}
                  as={'a'}
                  target={variant === 'bookstorePage' ? null : '_blank'}
                  rel={'noopener noreferrer'}
                  href={button.buttonLink}
                  whiteSpace={'unset'}
                  height={'auto'}
                  py={[3, 3]}
                  width={'100%'}
                  onClick={() => {
                    gaEvent('Karma Book', 'Event Link Click', subText, 1)
                  }}
                >
                  {buttonText}
                </Button>
              )
            })}

            {slug && variant !== 'bookstorePage' && (
              <ButtonWithSubtext
                href={`/ticketing/${slug}-event-only`}
                variant={'outline'}
                colorScheme={'blue'}
                text={
                  <>
                    Buy Event Only -{' '}
                    {getGeoValue({ data: eventPricing, country, region })}
                  </>
                }
                subText={'Copy of the book not included'}
              />
            )}

            {!isRecorded && (
              <Text fontSize={'sm'} maxW={'250px'} lineHeight={'1.3'}>
                Includes recording access for <strong>7 days</strong>{' '}
                {!isPast && 'after the stream'}
              </Text>
            )}
          </VStack>
        </>
      ) : (
        <Text>Registration will open soon!</Text>
      )}
    </Box>
  )
}

const Event = (props) => {
  const {
    event: {
      title,
      dateText,
      subText,
      buttons,
      image,
      eventId,
      duration,
      bookstore,
      isPast,
      isRecorded,
    },
    index,
    variant,
    country,
    region,
    bgColor,
  } = props

  const { eventPricing, slug } = eventData[eventId]

  const isImageRight = index % 2 === 0

  const templateAreas = [
    `"title"
     "text"
     "image"
     "button"
     "description"`,
    isImageRight
      ? `"title ."
         "text image"
         "button image"
         "description description"`
      : `". title"
         "image text"
         "image button"
         "description description"`,
  ]
  return (
    <>
      <Box
        id={slug}
        {...(index >= 1 && {
          borderTop: 'solid 2px',
          borderColor: 'tan.heading',
          pt: 12,
          mx: { base: 0, md: '-40px', lg: '-80px' },
        })}
      />
      <Grid
        templateColumns={[
          '1fr',
          isImageRight ? '1fr fit-content(200px)' : 'fit-content(200px) 1fr',
          isImageRight ? '1fr 250px' : '250px 1fr',
        ]}
        templateRows={['unset', 'auto min-content 1fr']}
        templateAreas={templateAreas}
        columnGap={12}
        rowGap={3}
        mb={[12, 16]}
        maxWidth={['340px', 'unset']}
        mx={'auto'}
      >
        <Text
          as={'div'}
          fontSize={['md', 'lg', 'xl']}
          gridArea={'title'}
          textTransform={'uppercase'}
        >
          {subText}
        </Text>

        <Box gridArea={'text'}>
          <Text
            as={'div'}
            fontSize={['3xl', null, '36px']}
            textStyle={'heading'}
            pb={1}
          >
            {title}
          </Text>
          {!isPast && (
            <Text as={'div'} fontSize={['md', 'lg', 'xl']} color={'#847244'}>
              {dateText}
            </Text>
          )}
          {duration && (
            <Text as={'div'} fontSize={['md', 'lg', 'xl']}>
              Duration: {duration}
            </Text>
          )}
          {bookstore && (
            <Text as={'div'} fontSize={['md']}>
              In partnership with {bookstore}
            </Text>
          )}
        </Box>

        <Box gridArea={'image'}>
          <Image
            src={image}
            alt={'Event'}
            htmlWidth={'500'}
            htmlHeight={'500'}
          />
        </Box>

        <Buttons
          buttons={buttons}
          variant={variant}
          country={country}
          region={region}
          eventPricing={eventPricing}
          slug={slug}
          subText={subText}
          isRecorded={isRecorded}
          isPast={isPast}
        />

        {eventId in eventDescriptions && (
          <Description eventId={eventId} bgColor={bgColor} />
        )}
      </Grid>
    </>
  )
}

export default Event
