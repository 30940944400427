import React from 'react'
import { TextLink } from '../../../elements/link'
import { eventIds } from '../../../../api/eventData'

const deepakChopraDescription = (
  <>
    <p style={{ fontWeight: 'bold' }}>
      How are karma and destiny connected?
      <br />
      Is my life predetermined by karma?
      <br />
      Does destiny decide my relationships?
    </p>
    <p>
      Join Sadhguru and Deepak Chopra as they explore the realm of karma,
      challenging all the misconceptions that have been historically woven
      around the concept. This opening event of the{' '}
      <strong>Karma - Virtual Book Tour</strong> will unravel the existential
      mechanism of karma, a dimension that puts you back in the driver’s seat of
      your life.
    </p>
    <p>
      <strong>About Deepak Chopra:</strong>
      <br />
      Deepak Chopra, MD, FACP, is a world-renowned pioneer in integrative
      medicine and has authored more than eighty-nine books. He is the founder
      of the Chopra Foundation, a nonprofit entity for research on well-being
      and humanitarianism, and Chopra Global, a modern-day whole-health company
      at the intersection of science and spirituality.
    </p>
  </>
)

const kunalNayyarDescription = (
  <>
    <p style={{ fontWeight: 'bold' }}>
      Is karma the cause of human suffering?
      <br />
      Does collective karma exist?
      <br />
      Can I break free from the karmic cycle?
    </p>
    <p>
      Sadhguru and actor Kunal Nayyar will explore the most compelling questions
      surrounding karma: Where did it all begin? How is it related to human
      suffering? Can karma be collective? How can we break free from the karmic
      cycle? Join this insightful exchange that will unravel how karma is
      connected to some of the most vital areas of human inquiry: the meaning of
      life and above all, how to live it at full throttle.
    </p>
    <p>
      <strong>About Kunal Nayyar:</strong>
      <br />
      Kunal Nayyar is a British-Indian actor. He is known for portraying Rajesh
      Koothrappali on the sitcom The Big Bang Theory (2007–2019). Nayyar
      published a book about his career journey, titled “Yes, My Accent is Real:
      and Some Other Things I Haven’t Told You,” in September 2015.
    </p>
  </>
)

const eventDescriptions = {
  1: deepakChopraDescription,
  2: (
    <>
      <p style={{ fontWeight: 'bold' }}>
        How does karma impact my health?
        <br />
        What does being healthy really mean?
        <br />
        How can I take charge of my health?
      </p>
      <p>
        Sadhguru and Dr. Mark Hyman will explore the fundamentals of health and
        its connection to karma. In the yogic science, health is not merely the
        absence of disease, but a state of wholesomeness that every individual
        is capable of crafting for themselves. Bringing together insights from
        yoga and functional medicine, this discussion will offer powerful tools
        to bring your body and mind to a state of effervescence and vibrancy.
      </p>
      <p>
        This conversation will be moderated by Nikki Walton. Nikki Walton is a
        best-selling and NAACP Image Award-nominated author, TV personality,
        licensed psychotherapist, and host of ‘New Growth’.
      </p>

      <p>
        <strong>About Dr. Mark Hyman:</strong>
        <br />
        Dr. Mark Hyman is leading a health revolution—one revolved around using
        food as medicine to support longevity, energy, mental clarity,
        happiness, and so much more. Dr. Hyman is a practicing family physician
        and an advocate in the field of Functional Medicine and a thirteen-time
        New York Times bestselling author.
      </p>

      <p>
        <TextLink href={'https://drhyman.com'} isExternal={true}>
          Visit Dr. Mark Hyman's website
        </TextLink>
      </p>
    </>
  ),
  3: (
    <>
      <p>
        Join Chetan Bhagat for an evening as he seeks Sadhguru's insight on the
        mysteries of Karma. Chetan Bhagat, who is India's youth icon and author
        of 12 blockbuster books, will ask everything you wanted to know about
        Karma but never got a chance to ask.
      </p>
      <p>
        Book your ticket to watch the event LIVE online and get a free copy of
        the Karma book with Sadhguru's special message. Free book will be
        delivered to a valid Indian address only.
      </p>
    </>
  ),
  4: (
    <>
      <p style={{ fontWeight: 'bold' }}>
        How are karma and memory connected?
        <br />
        Do my actions determine my karma?
        <br />
        Can I go beyond my karma?
      </p>
      <p>
        Author and speaker Philip Goldberg will join Sadhguru to explore the
        complexity of the karmic structure and the integral role of memory in
        its making. They will tap into the connection between action and karma
        -- how you perform your action determines what kind of karma you build
        or even dismantle. This discussion will clarify many misconceptions and
        point us to life’s tremendous possibility: that of absolute freedom.
      </p>
      <p>
        <strong>About Philip Goldberg:</strong>
        <br />
        Philip Goldberg is the author or co-author of numerous books; a public
        speaker and workshop leader; a spiritual counselor, meditation teacher
        and ordained Interfaith Minister. His latest book, published in August,
        2020, is Spiritual Practice for Crazy Times: Powerful Tools to Cultivate
        Calm, Clarity, and Courage.
      </p>
    </>
  ),
  5: kunalNayyarDescription,
  [eventIds.terryTamminen]: (
    <>
      <p style={{ fontWeight: 'bold' }}>
        What is the connection between karma and the environment?
        <br />
        Is climate change our karma?
        <br />
        Can we restore our planet?
      </p>
      <p>
        Environmentalist Terry Tamminen joins Sadhguru on the virtual stage to
        venture into the connection between karma and the life of the Earth. How
        does our individual and collective karma play a role in the ecological
        challenges and devastation we face today? How do we then take charge and
        navigate towards the restoration and thriving of Mother Earth? Join us
        to explore the prospects of shaping our ecological future and that of
        humanity at large.
      </p>
      <p>
        {' '}
        <strong>About Terry Tamminen:</strong>
        <br />
        Terry Tamminen was the architect of many groundbreaking sustainability
        policies as Secretary of the California Environmental Protection Agency
        for Governor Arnold Schwarzenegger. In February 2007, he founded the
        non-profit organization Seventh Generation Advisors (SGA) and was named
        Vanity Fair’s May 2007 Environmental Hero, and ranked by the Guardian as
        No. 1 in its “Top 50 People Who Can Save the Planet.”
      </p>
    </>
  ),
  [eventIds.deepakChopraAu]: deepakChopraDescription,
  [eventIds.markHymanAu]: (
    <>
      <p style={{ fontWeight: 'bold' }}>
        How does karma impact my health?
        <br />
        What does being healthy really mean?
        <br />
        How can I take charge of my health and food choices?
      </p>
      <p>
        Sadhguru and Dr. Mark Hyman will explore the fundamentals of health,
        nutrition, and their connection to karma. In the yogic science, health
        is not merely the absence of disease, but a state of wholesomeness that
        every individual is capable of crafting for themselves. Bringing
        together insights from yoga and functional medicine, this discussion
        will offer powerful tools to bring your body and mind to a state of
        effervescence and vibrancy.
      </p>
      <p>
        This conversation will be moderated by Nikki Walton. Nikki Walton is a
        best-selling and NAACP Image Award-nominated author, TV personality,
        licensed psychotherapist, and host of ‘New Growth’.
      </p>

      <p>
        <strong>About Dr. Mark Hyman:</strong>
        <br />
        Dr. Mark Hyman is leading a health revolution—one revolved around using
        food as medicine to support longevity, energy, mental clarity,
        happiness, and so much more. Dr. Hyman is a practicing family physician
        and an advocate in the field of Functional Medicine and a thirteen-time
        New York Times bestselling author.
      </p>

      <p>
        <TextLink href={'https://drhyman.com'} isExternal={true}>
          Visit Dr. Mark Hyman's website
        </TextLink>
      </p>
    </>
  ),
  [eventIds.kunalNayyarAu]: kunalNayyarDescription,
  [eventIds.clubhouse]: (
    <>
      <ul>
        <li>How are freedom and karma connected?</li>
        <li>
          How to live equanimously in a world of suffering and impermanence?
        </li>
        <li>Does meditation impact our karma?</li>
      </ul>

      <p>
        Sadhguru addresses questions from leaders, entrepreneurs, and seekers on
        the impact of karma on the roles we play, be it consciously or
        unconsciously. This session will tap into yoga as a path to shift from
        entanglement to involvement in all aspects of life, transforming you
        from a mere actor into the director of your destiny.
      </p>
    </>
  ),
  [eventIds.questionsOnKarma]: (
    <>
      <p>
        This is a collection of the most insightful conversations on Karma
        between Sadhguru and Demi Lovato, Mathew McConaughey, Tony & Sage
        Robbins, Maluma, Evan Carmichael, David Meltzer and more. This beautiful
        collage of topics delves into various aspects of karma, from the
        pragmatic to the unknown.
      </p>
    </>
  ),
}

export default eventDescriptions
